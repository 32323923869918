import { FETCH_SURVEYS_FAILURE, FETCH_SURVEYS_REQUEST, FETCH_SURVEYS_SUCCESS, SET_SURVEYS_CURRENT_PAGE, SET_SURVEYS_FILTERS, SET_SURVEYS_PER_PAGE, SET_SURVEYS_SORT_BY, SET_SURVEYS_SORT_ORDER } from "../actions/surveysActions";

  
  const initialState = {
    surveys: [],
    totalPages: 1,
    currentPage: 1,
    filters: {},
    sortBy: 'id',
    sortOrder: 'desc',
    perPage: 10,
    loading: false,
    error: null,
  };
  
  const surveysReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SURVEYS_REQUEST:
        return { ...state, loading: true, error: null };
      case FETCH_SURVEYS_SUCCESS:
        return {
          ...state,
          surveys: action.payload.surveys,
          totalPages: action.payload.total_pages,
          currentPage: action.payload.current_page,
          loading: false,
        };
      case FETCH_SURVEYS_FAILURE:
        return { ...state, loading: false, error: action.payload };
      case SET_SURVEYS_FILTERS:
        return { ...state, filters: action.payload };
      case SET_SURVEYS_SORT_BY:
        return { ...state, sortBy: action.payload };
      case SET_SURVEYS_SORT_ORDER:
        return { ...state, sortOrder: action.payload };
      case SET_SURVEYS_PER_PAGE:
        return { ...state, perPage: action.payload };
      case SET_SURVEYS_CURRENT_PAGE:
        return { ...state, currentPage: action.payload };
      default:
        return state;
    }
  };
  
  export default surveysReducer;
  