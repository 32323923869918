import React from 'react';
import { Container } from '@mui/material';

const ProfilAdmin = () => {
  return (
    <Container sx={{ mt: 2 }}>
      <h1>Rôle Admin Profile</h1>
      <p>Manage your profile settings here.</p>
      {/* Add content for profile management */}
    </Container>
  );
};

export default ProfilAdmin;
