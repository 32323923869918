
const Colors = {
  DARK_BLUE: '#334359',
  LIGHT_BLUE: '#5D6F83',
  DARK_BLUE_2: "#34435A",
  VERY_DARK_BLUE: "#00203A",
  VERY_DARK_BLUE_2: "#16253D",
  GREEN: "#3FB4B1",
  DARK_GREEN: "#1A6A86",
  MEDIUM_GREEN: "#178683",
  
  MEDIUM_GREY:"#AFC6C4",
  LIGHT_GREY: '#D9D9D9',
  VERY_LIGHT_GREY: '#F3F3F3',
  PINK_GREY: " #E7D4CF",
  TITLE_1: "#363F5E",
  TITLE_2: "#686668",
  TITLE_3: "#637B83",
 TEXT_BODY: "#334A52",
    YELLOW: "#ffd402",
  
  
  
  };
  
  export default Colors;
  