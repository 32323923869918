import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { Box, Card, CardContent, CircularProgress, Grid, IconButton, InputLabel, Menu, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import Colors from "src/styles/Colors";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { SmallLightText } from 'src/styles/CustomStyles';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { setPerPage } from 'src/store/actions/surveysActions';
import { setCurrentPage } from 'src/store/actions/surveysActions';
import { fetchSurveys } from 'src/store/actions/surveysActions';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTablePagination } from 'src/styles/CustomStyles';
import NotStartedOutlinedIcon from '@mui/icons-material/NotStartedOutlined';
import TeamsHorizontalBarChart from './components/TeamsHorizontalBarChart';
import ReplyAllOutlinedIcon from '@mui/icons-material/ReplyAllOutlined';
import { useNavigate } from 'react-router-dom';
import useAxios from 'src/services/apiInterceptor';
import { addOrUpdateUserSurvey } from 'src/store/actions/currentUserSurveysActions';
import { FilterButton } from 'src/styles/CustomStyles';
import { FilterListFormControl } from 'src/styles/CustomStyles';
import { FiltersTextField } from 'src/styles/CustomStyles';
import * as XLSX from 'xlsx';
import { setFilters } from 'src/store/actions/surveysActions';
import { setSortBy } from 'src/store/actions/surveysActions';
import { setSortOrder } from 'src/store/actions/surveysActions';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import AssignTeams from './Modals/AssignTeams';
import SurveysCounter from './components/SurveysCounter';
import { fetchAllTeams } from 'src/store/actions/teamActions';
import SurveysTeamsCounter from './components/SurveyTeamsCounter';
import SurveyTeamsFinishedCounter from './components/SurveyTeamsFinishedCounter';
import { BarChartRounded, ChatRounded, GroupOutlined } from '@mui/icons-material';




const exportToExcel = (data) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Liste des Questionnaires');
    XLSX.writeFile(wb, 'liste_des_questionnaires.xlsx');
};
const QuestionnaireList = () => {
  
    const dispatch = useDispatch();
    const {
      surveys,
      totalPages,
      currentPage,
      filters,
      sortBy,
      sortOrder,
      perPage,
      loading,
      error
    } = useSelector((state) => state.surveys);
  
    const [page, setPage] = useState(currentPage - 1);
    const [rowsPerPage, setRowsPerPage] = useState(perPage);
    // eslint-disable-next-line no-unused-vars
    const [selectedSurvey, setSelectedSurvey] = useState(null);
    const navigate = useNavigate();
    const api = useAxios();
    
    
    
    useEffect(() => {
      dispatch(fetchSurveys(page + 1));
    }, [dispatch, filters, sortBy, sortOrder, rowsPerPage, page]);
  
    useEffect(() => {
      if (page >= totalPages && totalPages > 0) {
        setPage(totalPages - 1);
      }
    }, [totalPages, page]);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      dispatch(setCurrentPage(newPage + 1));
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      dispatch(setPerPage(parseInt(event.target.value, 10)));
      setPage(0);
    };
  
    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenuClick = (event, survey) => {
      setSelectedSurvey(survey);
      console.log("survey from menuclick", survey, selectedSurvey)
      setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedSurvey(null);
    };

    const handleClearFilters = () => {
        dispatch(setFilters({ title: '' }));
        dispatch(setSortBy('created_at'));
        dispatch(setSortOrder('desc'));
    };
    const handleNavigateToPasser = async (survey) => {
        try {
        const response = await api.get(`/surveys/check_current_user_survey/${survey.id}`, {
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        });

        /*    const result = await response.json(); */
        console.log(response.data)
        if (response.data.exists) {
            console.log(response.data.users_surveys)
             // Add the survey to Redux state, avoiding redundancy
            dispatch(addOrUpdateUserSurvey(response.data.users_surveys));
            console.log({ selectedSurvey: survey, users_surveys: response.data.users_surveys })
            // Navigate with the existing entry
            navigate('/manager/questionnaire/passer', { state: { selectedSurvey: survey } });
        } else {
            // Navigate without an existing entry
            navigate('/manager/questionnaire/passer', { state: { selectedSurvey: survey } });
        }
    } catch (error) {
        console.error('Error checking user survey:', error);
    }
    };


    const [assignTeamsModalOpen, setAssignTeamsModalOpen] = useState(false);
    const [assignTeamsSurvey, setAssignTeamsSurvey] = useState(null);

    const handleAssignTeamsOpen = (survey) => {
        setAssignTeamsSurvey(survey);
        setAssignTeamsModalOpen(true);
    };

    const handleAssignTeamsClose = () => {
        setAssignTeamsSurvey(null);

        setAssignTeamsModalOpen(false);
        
    };

    const handleAssignTeamsSubmit = () => {
        // Handle modal submission logic
        handleAssignTeamsClose();
        dispatch(setFilters({ title: '' }));
        dispatch(setSortBy('created_at'));
        dispatch(setSortOrder('desc'));
    };

    const { allTeams = [], loading: teamsLoading, error: teamsError  } = useSelector(state => state.teams);

    useEffect(() => {
      dispatch(fetchAllTeams());
    }, [dispatch]);

    return (
      <Box sx={{ mt: 0 }}>
        <Box mb={2} variant="section">
                <Typography variant="h6" gutterBottom color={Colors.TITLE_1}>
                    Vue d&apos;ensemble des questionnaires
                </Typography>
                <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
                    {/* First Column */}
                    <Grid item xs={12} sm={6} md={3} sx={{ minHeight: '100px' }}>
                        <SurveysTeamsCounter />
                    </Grid>
                    <Grid item xs={12}  sm={6} md={3} sx={{ minHeight: '100px' }}>
                        <SurveyTeamsFinishedCounter /> 
                    </Grid>
                    <Grid item xs={6}  sm={6} md={3} sx={{ minHeight: '80px' }}>
                    <SurveysCounter /> 
                    </Grid>
                    <Grid item xs={6}  sm={6} md={3} sx={{ minHeight: '80px' }}>
                        <Card
                        onClick={() => handleAssignTeamsOpen(null)}
                        sx={{
                            borderRadius: 4,
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            background: Colors.LIGHT_BLUE,
                            cursor:"pointer"
                        }}
                        >
                            <CardContent 
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'start', 
                                    padding:"6px !important",
                                    gap: '3px'
                            }}>
                            {/* Counter */}
                            <Box
                                        sx={{
                                            padding: '10px',
                                            bgcolor: Colors.MEDIUM_GREEN,
                                            borderRadius: '10px',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <GroupAddOutlinedIcon fontSize="medium" style={{ color: "#fff" }} />
                                        
                                    </Box>

                                    {/* Typography */}
                                    <Typography
                                    color={"#fff"}
                                    variant="h6"
                                    component="div"
                                    gutterBottom
                                    style={{ maxWidth: '70%', lineHeight: '1.3', marginBottom:0 }}
                                    >
                                    Affecter un Questionnaire
                                    </Typography>                    
                            </CardContent>
                        </Card>
                        
                    </Grid>
                    

                    {/* Second Column */}
                  {/*   <Grid item xs={12} sm={6}>
                      <TeamsHorizontalBarChart />
                    </Grid> */}
                </Grid>
        </Box>
        <Box display="flex" justifyContent="end" flexWrap="wrap" gap={2}>
                
                {/* Last Name Filter */}
                <FiltersTextField
                    label="Rechercher..."
                    variant="outlined"
                    size="small"
                    value={filters.title || ''}
                    onChange={(e) => dispatch(setFilters({ ...filters, title: e.target.value }))}
                />

              

                {/* Sorting Options */}
                <FilterListFormControl size="small">
                    <InputLabel id="tri-label">Tri Par</InputLabel>
                    <Select
                    labelId="tri-label"
                    id="tri-value"
                    label="Tri Par"
                    value={sortBy}
                    onChange={(e) => dispatch(setSortBy(e.target.value))}
                    >
                    <MenuItem value="id">ID</MenuItem>
                    <MenuItem value="first_name">Titre</MenuItem>
                    <MenuItem value="created_at">Créé Le</MenuItem>
                    </Select>
                </FilterListFormControl>

                {/* Sorting Order */}
                <FilterListFormControl size="small">
                    <InputLabel  id="tri-order-label">Ordre de Tri</InputLabel>
                    <Select
                     labelId="tri-order-label"
                     id="tri-order-value"
                     label="Ordre de Tri"
                    value={sortOrder}
                    onChange={(e) => dispatch(setSortOrder(e.target.value))}
                    >
                    <MenuItem value="asc">Ascendant</MenuItem>
                    <MenuItem value="desc">Descendant</MenuItem>
                    </Select>
                </FilterListFormControl>

                <IconButton onClick={handleClearFilters} color="primary">
                    <RefreshOutlinedIcon />
                </IconButton>
                {/* Export Button */}
                <FilterButton
                    variant="contained"
                    color="primary"
                    startIcon={<FileDownloadOutlinedIcon />}
                    onClick={() => exportToExcel(surveys)}
                >
                    Exporter
                </FilterButton>
        </Box>
        {/* Table Container */}
        <Box sx={{ flexGrow: 1, overflowX: 'auto', mt: 1 }}>
          <Table sx={{ width: '100%', background: "#fff", borderRadius: "15px" }}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Equipes</TableCell>
                <TableCell>Sections</TableCell>
                <TableCell>Sous-sections</TableCell>
                <TableCell>Questions</TableCell>
                <TableCell>Crée le</TableCell>
                <TableCell>Modifié le</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow style={{ height: 270 }}>
                  <TableCell colSpan={7} align="center">
                    <Typography>Loading...</Typography>
                  </TableCell>
                </TableRow>
              ) : error ? (
                <TableRow style={{ height: 270 }}>
                  <TableCell colSpan={7} align="center">
                    <Typography>Error: {error}</Typography>
                  </TableCell>
                </TableRow>
                
              ) : (
                surveys.map((survey) => (
                  <TableRow key={survey.id}>
                    <TableCell>{survey.id}</TableCell>
                    <TableCell>{survey.title}</TableCell>
                    <TableCell>{survey.teams}</TableCell>
                    <TableCell>{survey.sections}</TableCell>
                    <TableCell>{survey.subsections}</TableCell>
                    <TableCell>{survey.questions} </TableCell>
                    <TableCell>
                        {new Date(survey.created_at).toLocaleString('fr-FR', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </TableCell>
                      <TableCell>
                        {new Date(survey.updated_at).toLocaleString('fr-FR', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </TableCell>
                    <TableCell sx={{ position: 'sticky', right: 0, background: '#fff' }}>
                        <IconButton sx={{ color: Colors.GREEN}} onClick={() => handleNavigateToPasser(survey) }>
                            <NotStartedOutlinedIcon />
                        </IconButton>
                        <IconButton sx={{ color: Colors.YELLOW}} onClick={() => handleAssignTeamsOpen(survey) }>
                            <ReplyAllOutlinedIcon />
                        </IconButton>
                        <IconButton color="default" onClick={() => null}>
                            <GroupOutlined />
                        </IconButton>
                        
                      <IconButton
                        onClick={(event) => handleMenuClick(event, survey)}
                      >
                        <BarChartRounded />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        PaperProps={{
                          style: {
                            width: 200,
                          },
                        }}
                      >
                        <MenuItem onClick={null}>
                         
                        { // eslint-disable-next-line no-constant-condition
                            (2 * 10) == 1 ? (
                            <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} />
                          ) : (
                            null
                          )}
                          Afficher le bilan
                        </MenuItem>

                      </Menu>

                   
                  </TableCell>
                  </TableRow>
                ))
              )}
              {
                 surveys.length === 0 &&
                 (
                 <TableRow style={{ height: 270 }}>
                     <TableCell colSpan={7} align="center">
                         <Typography>Aucune Donnée</Typography>
                     </TableCell>
                 </TableRow>
                
                   ) 
              }
            </TableBody>
        </Table>
         {/* Pagination */}
         <CustomTablePagination
          rowsPerPageOptions={[10, 25,50,100]}
          component="div"
          count={totalPages * rowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Afficher"
          nextIconButtonProps={{
            'aria-label': 'Page suivante',
          }}
          backIconButtonProps={{
            'aria-label': 'Page précédente',
          }}
        />
        </Box>  
         {/* Assign Teams Modal */}
        <AssignTeams
            open={assignTeamsModalOpen}
            onClose={handleAssignTeamsClose}
            onSubmit={handleAssignTeamsSubmit}
            survey={assignTeamsSurvey?.id}
            surveys={surveys}
            teams={allTeams}
        />    
         {/* Assign Teams Modal */}
       {/*   <EditSurvey
            open={assignTeamsModalOpen}
            onClose={handleAssignTeamsClose}
            onSubmit={handleAssignTeamsSubmit}
            survey={assignTeamsSurvey?.id}
            surveys={surveys}
            teams={allTeams}
        />     */}
        {/* Add content for viewing and managing reports */}
      </Box>
    );
};
export default QuestionnaireList;