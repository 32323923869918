import React, { useEffect, useState } from 'react';
import { Typography, Box, MenuItem, Menu, IconButton, TableCell, TableRow, TableBody, Table, TableHead, InputLabel, Select } from '@mui/material';
import { fetchTeamsCollaborators } from 'src/store/actions/teamCollaboratorsActions';
import useAxios from 'src/services/apiInterceptor';
import { useDispatch, useSelector } from 'react-redux';
import { setPerPage } from 'src/store/actions/teamCollaboratorsActions';
import { setCurrentPage } from 'src/store/actions/teamCollaboratorsActions';
import { API_URL } from 'src/config';
import { showSnackbar } from 'src/store/actions/snackbarActions';
import { CustomTablePagination } from 'src/styles/CustomStyles';
import Colors from 'src/styles/Colors';
import * as XLSX from "xlsx";
import { FilterListFormControl } from 'src/styles/CustomStyles';
import { setSortOrder } from 'src/store/actions/teamCollaboratorsActions';
import { FilterButton } from 'src/styles/CustomStyles';
import { setSortBy } from 'src/store/actions/teamCollaboratorsActions';
import { setFilters } from 'src/store/actions/teamCollaboratorsActions';
import { FiltersTextField } from 'src/styles/CustomStyles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import InsertchartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { fetchAllTeams } from 'src/store/actions/teamActions';
import CollaboratorEditModal from './CollaboratorsModals/CollaboratorEditModal';
import DeleteCollaboratorModal from './CollaboratorsModals/DeleteCollaboratorModal';
import { PasswordOutlined, PersonOffOutlined } from '@mui/icons-material';

const exportToExcel = (data) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Liste des Collaborateurs');
    XLSX.writeFile(wb, 'liste_des_collaborateurs.xlsx');
};
  


const CollaboratorList = () => {
    const dispatch = useDispatch();
    const {
      collaborators,
      totalPages,
      currentPage,
      filters,
      sortBy,
      sortOrder,
      perPage,
      loading,
      error
    } = useSelector((state) => state.collaborators);
    // eslint-disable-next-line no-unused-vars
    const { allTeams = [], loading: teamsLoading, error: teamsError  } = useSelector(state => state.teams);

    useEffect(() => {
      dispatch(fetchAllTeams());
    }, [dispatch]);

    const [page, setPage] = useState(currentPage - 1);
    const [rowsPerPage, setRowsPerPage] = useState(perPage);
    
    const [errors, setErrors] = useState({});
    const api = useAxios();
    const [loadingSubmit, setLoadingSubmit] = useState(false); // Add loading state
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    
    
    
    useEffect(() => {
        dispatch(fetchTeamsCollaborators(page + 1));
        console.log(collaborators)
    }, [dispatch, filters, sortBy, sortOrder, rowsPerPage, page]);
  
    useEffect(() => {
      if (page >= totalPages && totalPages > 0) {
        setPage(totalPages - 1);
      }
    }, [totalPages, page]);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      dispatch(setCurrentPage(newPage + 1));
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      dispatch(setPerPage(parseInt(event.target.value, 10)));
      setPage(0);
    };

     const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  
    const handleEditClick = (team) => {
          console.log(team)
        setSelectedCollaborator(team);
        setEditModalOpen(true);
    };
  
    const handleEditModalClose = () => {
      setEditModalOpen(false);
      setSelectedCollaborator(null);
    };
   
     const handleEditSubmit = async () => {
        setLoadingSubmit(true); // Start loading
        // Reset errors
      
        setErrors({});
      try {
          const updateData = {
              team_id: selectedCollaborator.team_id,
              first_name: selectedCollaborator.first_name,
              last_name: selectedCollaborator.last_name,
              email: selectedCollaborator.email
          };

          await api.put(`${API_URL}/teams/collaborator/${selectedCollaborator.id}`, updateData);
          dispatch(fetchTeamsCollaborators(page + 1)); // Refresh teams list
          dispatch(showSnackbar('Collaborateur id: '+selectedCollaborator.id+" "+selectedCollaborator.first_name +" mis à jour avec succés ", 'error'));

          handleEditModalClose(); // Close the modal
        } catch (error) {
          console.error("Failed to update team:", error);
          if (error?.response?.data) {
            const validationErrors = error.response.data || {}; // Adjust based on your API response structure
            setErrors(validationErrors);
            dispatch(showSnackbar('Échec de la mise à jour de l’équipe id: '+selectedCollaborator.id+" "+selectedCollaborator.first_name, 'error'));
          } else {
              dispatch(showSnackbar('Échec de la mise à jour de l’équipe id: '+selectedCollaborator.id+" "+selectedCollaborator.first_name+' '+ error.message , 'error'));
          }
      } finally {
        setLoadingSubmit(false); // Start loading
      }
     
    }; 
  
    const handleDeleteClick = (user) => {
      setSelectedCollaborator(user);
      setDeleteModalOpen(true);
    };
  
    const handleDeleteModalClose = () => {
      setDeleteModalOpen(false);
      setSelectedCollaborator(null);
    };
  
    const handleDeleteSubmit = async () => {
      setLoadingSubmit(true);
      try {
        await api.delete(`${API_URL}/teams/collaborator/${selectedCollaborator.id}`);
        dispatch(fetchTeamsCollaborators());
          handleDeleteModalClose();
        dispatch(showSnackbar('Collaborateur supprimé avec succés', 'success'));
          
      } catch (error) {
        console.error("Failed to delete team:", error);
        dispatch(showSnackbar('Échec de la suppression du collaborateur', 'error'));
      } finally {
        setLoadingSubmit(false);
      }
    };
  
    const handleClearFilters = () => {
        dispatch(setFilters({ first_name: '', last_name: '', team: '' }));
        dispatch(setSortBy('id'));
        dispatch(setSortOrder('asc'));
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenuClick = (event, user) => {
      setSelectedCollaborator(user);
      setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedCollaborator(null);
  
    };
    
   

   
  
  return (
    <Box>
       {/* Teams Table */}
       <Box>
        <Box mb={2} style={{ display: "flex", flexWrap:"wrap", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h6" gutterBottom color="textPrimary">
                Liste des Collaborateurs
            </Typography>

            <Box display="flex" flexWrap="wrap" gap={2}>
                {/* First Name Filter */}
                <FiltersTextField
                    label="Prénom"
                    variant="outlined"
                    size="small"
                    value={filters.first_name || ''}
                    onChange={(e) => dispatch(setFilters({ ...filters, first_name: e.target.value }))}
                />

                {/* Last Name Filter */}
                <FiltersTextField
                    label="Nom de famille"
                    variant="outlined"
                    size="small"
                    value={filters.last_name || ''}
                    onChange={(e) => dispatch(setFilters({ ...filters, last_name: e.target.value }))}
                />

                {/* Team Filter */}
                <FilterListFormControl size="small">
                    <InputLabel>Équipe</InputLabel>
                    <Select
                    value={filters.team_id || ''}
                    onChange={(e) => dispatch(setFilters({ ...filters, team_id: e.target.value }))}
                    >
                    {allTeams.map((team) => (
                        <MenuItem key={team.id} value={team.id}>
                        {team.name}
                        </MenuItem>
                    ))}
                    </Select>
                </FilterListFormControl>

                {/* Sorting Options */}
                <FilterListFormControl size="small">
                    <InputLabel>Tri Par</InputLabel>
                    <Select
                    value={sortBy}
                    onChange={(e) => dispatch(setSortBy(e.target.value))}
                    >
                    <MenuItem value="id">ID</MenuItem>
                    <MenuItem value="first_name">Prénom</MenuItem>
                    <MenuItem value="last_name">Nom de famille</MenuItem>
                    <MenuItem value="team.name">{"Nom de l'équipe"}</MenuItem>
                    <MenuItem value="created_at">Créé Le</MenuItem>
                    </Select>
                </FilterListFormControl>

                {/* Sorting Order */}
                <FilterListFormControl size="small">
                    <InputLabel>Ordre de Tri</InputLabel>
                    <Select
                    value={sortOrder}
                    onChange={(e) => dispatch(setSortOrder(e.target.value))}
                    >
                    <MenuItem value="asc">Ascendant</MenuItem>
                    <MenuItem value="desc">Descendant</MenuItem>
                    </Select>
                </FilterListFormControl>

                <IconButton onClick={handleClearFilters} color="primary">
                    <RefreshOutlinedIcon />
                </IconButton>
                {/* Export Button */}
                <FilterButton
                    variant="contained"
                    color="primary"
                    startIcon={<FileDownloadOutlinedIcon />}
                    onClick={() => exportToExcel(collaborators)}
                >
                    Exporter
                </FilterButton>
            </Box>

        </Box>

        {/* Table Container */}
        <Box sx={{ flexGrow: 1, overflowX: 'auto', mt: 2 }}>
          <Table sx={{ width: '100%', background: "#fff", borderRadius: "15px" }}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Prénom</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Equipe</TableCell>
                <TableCell>Crée le</TableCell>
                <TableCell>Modifié le</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow style={{ height: 300 }}>
                  <TableCell colSpan={7} align="center">
                    <Typography>Loading...</Typography>
                  </TableCell>
                </TableRow>
              ) : error ? (
                <TableRow style={{ height: 300 }}>
                  <TableCell colSpan={7} align="center">
                    <Typography>Error: {error}</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                collaborators.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.first_name}</TableCell>
                    <TableCell>{user.last_name}</TableCell>
                    <TableCell>{user.team.name}</TableCell>
                    <TableCell>
                        {new Date(user.created_at).toLocaleString('fr-FR', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </TableCell>
                      <TableCell>
                        {new Date(user.updated_at).toLocaleString('fr-FR', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </TableCell>
                    <TableCell sx={{ position: 'sticky', right: 0, background: '#fff' }}>
                        <IconButton sx={{ color: Colors.MEDIUM_GREEN }} onClick={() => console.log(`Show chart for ${user.id}`)}>
                          <InsertchartOutlinedIcon />
                        </IconButton>
                        <IconButton color="default" onClick={() => handleEditClick(user)}>
                            <EditOutlinedIcon />
                        </IconButton>
                        <IconButton sx={{ color: "#ff0000"}} onClick={() => handleDeleteClick(user)}>
                          <DeleteOutlinedIcon />
                      </IconButton>
                      <IconButton
                        onClick={(event) => handleMenuClick(event, user)}
                      >
                        <MoreVertOutlinedIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        PaperProps={{
                          style: {
                            width: 200,
                          },
                        }}
                      >
                        <MenuItem onClick={()=> null}>
                            {/*  {loadingSubmit ? (
                                <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} />
                            ) : ( */}
                            <PasswordOutlined sx={{ mr: 1 }} />
                            {/* )} */}
                            Regénérer le mot de passe et renvoyer par email
                                </MenuItem>
                                <MenuItem onClick={()=> null}>
                            {/*  {loadingSubmit ? (
                                <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} />
                            ) : ( */}
                            <PersonOffOutlined sx={{ mr: 1 }} />
                            {/* )} */}
                            Désactiver le compte
                        </MenuItem>
                      </Menu>

                   
                  </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
               
        </Box>

        {/* Pagination */}
        <CustomTablePagination
          rowsPerPageOptions={[10, 25,50,100]}
          component="div"
          count={totalPages * rowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Afficher"
          nextIconButtonProps={{
            'aria-label': 'Page suivante',
          }}
          backIconButtonProps={{
            'aria-label': 'Page précédente',
          }}
        />
      </Box>

       
      
      {/* Edit Team Modal */}
      {selectedCollaborator && (
        <CollaboratorEditModal
          open={editModalOpen}
          onClose={handleEditModalClose}
          onSubmit={handleEditSubmit}
          collaboratorData={selectedCollaborator}
          setCollaboratorData={setSelectedCollaborator}
          loading={loadingSubmit} // Pass loading state
          errors={errors} // Pass any validation errors here
        />
      )}
       {/* Delete Team Modal */}
       {selectedCollaborator && (
        <DeleteCollaboratorModal
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          onSubmit={handleDeleteSubmit}
          collaboratorData={selectedCollaborator}
          loading={loadingSubmit}
        />
      )}
    </Box>
  );
};

export default CollaboratorList;
