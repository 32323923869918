import Colors from "./Colors";

import { Box, Button, FormControl, TablePagination, TextField, styled } from "@mui/material";

export const FiltersTextField = styled(TextField)(() => ({
    
  '& .MuiOutlinedInput-root': {
      width: "120px",
      borderRadius:"10px",

    '& fieldset': {
      borderColor: Colors.TITLE_2, // Use your color here
    },
    '&:hover fieldset': {
      borderColor: Colors.GREEN,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.GREEN,
    },
  },
  '& .MuiInputLabel-root': {
    color:Colors.TITLE_2,
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: Colors.GREEN,
  },
}));

export const FilterListFormControl = styled(FormControl)(() => ({
    width: "120px",
    borderRadius: "10px",

    '& .MuiOutlinedInput-root': {
        borderRadius:"10px",
    '& fieldset': {
      borderColor: Colors.TITLE_2,
    },
    '&:hover fieldset': {
      borderColor: Colors.GREEN,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.GREEN,
    },
  },
  '& .MuiInputLabel-root': {
    color:Colors.TITLE_2,
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: Colors.GREEN,
  },
}));

export const FilterButton = styled(Button)(() => ({
    backgroundColor: Colors.DARK_BLUE,
    color: '#fff', 
    borderRadius:"10px",
    textTransform: 'none', // Prevent uppercase text
    '&:hover': {
      backgroundColor: Colors.LIGHT_BLUE,
    },
    '&:focus': {
      outline: 'none', 
    },
}));
  

export const PaginatorButton = styled(Button)(() => ({
    borderColor: Colors.GREEN,
    color: Colors.TITLE_2, // Button label color
    borderWidth: 1,
    borderStyle: 'solid',
    textTransform: 'none', // Prevent uppercase text
    '&:hover': {
      borderColor: Colors.GREEN, // Keep border color consistent on hover
      backgroundColor: 'transparent', // Optionally set background color on hover
    },
    '&:focus': {
      outline: 'none', // Optional: Remove outline on focus
    },
}));
  
export const CustomTablePagination = styled(TablePagination)((/* { theme } */) => ({
  '& .MuiTablePagination-actions .MuiIconButton-root': {
    color: Colors.GREEN, // Set the icon color to GREEN
  },
  '& .MuiTablePagination-selectIcon': {
    color: Colors.GREEN, // Set the rows per page select icon color to GREEN
  },
}));

export const FormTextField = styled(TextField)(() => ({
    
    '& .MuiOutlinedInput-root': {
       
        borderRadius:"10px",
  
      '& fieldset': {
        borderColor: Colors.TITLE_2, // Use your color here
      },
      '&:hover fieldset': {
        borderColor: Colors.GREEN,
      },
      '&.Mui-focused fieldset': {
        borderColor: Colors.GREEN,
      },
    },
    '& .MuiInputLabel-root': {
      color:Colors.TITLE_2,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: Colors.GREEN,
    },
}));
  
export const FormButton = styled(Button)(() => ({
    backgroundColor: Colors.DARK_BLUE,
    color: '#fff', 
    borderRadius: "10px",
    padding:"6px 30px",
    textTransform: 'none', // Prevent uppercase text
    '&:hover': {
        backgroundColor: Colors.LIGHT_BLUE,
        color: '#fff', 
    },
    '&:active': {
        backgroundColor: Colors.LIGHT_BLUE,
        color: '#fff', 
    },
    '&:disabled': {
        backgroundColor: Colors.LIGHT_BLUE,
        color: '#fff', 
    },
    '&:focus': {
        backgroundColor: Colors.LIGHT_BLUE,
        color: '#fff',
        outline: 'none', 
    },
}));
export const FormButtonCancel = styled(Button)(() => ({
    borderColor: Colors.DARK_BLUE,
    color: Colors.TITLE_2, 
    padding:"6px 30px",
    borderRadius:"10px",
    textTransform: 'none', // Prevent uppercase text
    '&:hover': {
        borderColor: Colors.GREEN,
    },
    '&:focus': {
      outline: 'none', 
    },
}));

export const SmallLightText = styled('span')(() => ({
    fontSize: "10px",
    color: Colors.LIGHT_GREY, 
   
}));

export const ScrollingBox = styled(Box)(({ theme }) => ({
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: Colors.GREEN,
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: Colors.MEDIUM_GREEN,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: Colors.DARK_BLUE,
    },
  }));