/* eslint-disable react/prop-types */
// components/TeamModal.js
import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton, CircularProgress } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FormTextField, FormButton, FormButtonCancel } from 'src/styles/CustomStyles';
import Colors from 'src/styles/Colors';

const TeamAddModal = ({ open, onClose, onSubmit, teamData, setTeamData, errors, loading }) => {
 
    
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setTeamData({ ...teamData, [name]: value });
    };

 
    
  return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                    <Box sx={{ position: 'relative',  py: 1, display: 'flex', alignItems: 'center' }}>
                        <img src="/teams_add.png" alt="Icon" style={{ width: 24, height: 24, marginRight: 8 }} />
                        <Typography variant="h6" color={Colors.DARK_GREEN} fontWeight={700}>
                        Ajouter une équipe
                        </Typography>
                        <IconButton
                        onClick={onClose}
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                        >
                        <CloseOutlinedIcon />
                        </IconButton>
                    </Box>
            </DialogTitle>
   
            <DialogContent>
                <FormTextField
                autoFocus
                margin="dense"
                label="Nom"
                name="name"
                fullWidth
                variant="outlined"
                value={teamData.name}
                onChange={handleInputChange}
                error={Boolean(errors.name)}
                helperText={errors.name}
                />
                <FormTextField
                margin="dense"
                label="Département"
                name="department"
                fullWidth
                variant="outlined"
                value={teamData.department}
                onChange={handleInputChange}
                error={Boolean(errors.department)}
                helperText={errors.department}
                />
                <FormTextField
                margin="dense"
                label="Max Collaborateurs"
                name="max_collaborators"
                type="number"
                fullWidth
                variant="outlined"
                value={teamData.max_collaborators}
                onChange={handleInputChange}
                error={Boolean(errors.max_collaborators)}
                helperText={errors.max_collaborators}
              />
              
            </DialogContent>
            <DialogActions
                sx={{ px: 2, py: 1 }}
                style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}
          >
               {/* <Tooltip title="Importer un fichier csv : first_name,last_name,email \n John,Doe,john.doe@example.com" arrow>
               
                    <FormButtonCancel
                    onClick={null}
                    startIcon={<UploadFileOutlinedIcon />}
                    variant="outlined"
              >
                      Importer des collaborateurs
                    </FormButtonCancel>
                </Tooltip> */}
                <Box
                display="flex"
                alignItems="center"
                gap={1}
                > 
                    <FormButtonCancel variant="outlined" onClick={onClose}>Annuler</FormButtonCancel>
                    <FormButton onClick={onSubmit}>
                        {loading ? <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }}/> : null} Ajouter
                    </FormButton>
                </Box>
            </DialogActions>
        </Dialog>
  );
};

export default TeamAddModal;
