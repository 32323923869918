/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton, CircularProgress, MenuItem } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FormTextField, FormButton, FormButtonCancel } from 'src/styles/CustomStyles';
import Colors from 'src/styles/Colors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllTeams } from 'src/store/actions/teamActions';

const CollaboratorEditModal = ({ open, onClose, onSubmit, collaboratorData, setCollaboratorData, errors, loading }) => {
    const { allTeams = [], loading: teamsLoading, error: teamsError } = useSelector(state => state.teams);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllTeams());
    }, [dispatch]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCollaboratorData({ ...collaboratorData, [name]: value });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Box sx={{ position: 'relative', py: 1, display: 'flex', alignItems: 'center' }}>
                    <img src="/teams_add.png" alt="Icon" style={{ width: 24, height: 24, marginRight: 8 }} />
                    <Typography variant="h6" color={Colors.DARK_GREEN} fontWeight={700}>
                        Mise à jour du collaborateur {collaboratorData.first_name} {collaboratorData.last_name}
                    </Typography>
                    <IconButton
                        onClick={onClose}
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                        <CloseOutlinedIcon />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>
                <FormTextField
                    autoFocus
                    margin="dense"
                    label="Prénom"
                    name="first_name"
                    fullWidth
                    variant="outlined"
                    value={collaboratorData.first_name}
                    onChange={handleInputChange}
                    error={Boolean(errors.first_name)}
                    helperText={errors.first_name}
                />
                <FormTextField
                    margin="dense"
                    label="Nom de famille"
                    name="last_name"
                    fullWidth
                    variant="outlined"
                    value={collaboratorData.last_name}
                    onChange={handleInputChange}
                    error={Boolean(errors.last_name)}
                    helperText={errors.last_name}
                />
                <FormTextField
                    margin="dense"
                    label="Email"
                    name="email"
                    fullWidth
                    variant="outlined"
                    value={collaboratorData.email}
                    onChange={handleInputChange}
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                />
                <FormTextField
                    margin="dense"
                    label="Équipe"
                    name="team_id"
                    select
                    fullWidth
                    variant="outlined"
                    value={collaboratorData.team_id}
                    onChange={handleInputChange}
                    error={Boolean(errors.team_id)}
                    helperText={errors.team_id}
                >
                    {allTeams.map((team) => (
                        <MenuItem key={team.id} value={team.id}>
                            {team.name}
                        </MenuItem>
                    ))}
                </FormTextField>
            </DialogContent>

            <DialogActions sx={{ px: 2, py: 1 }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box display="flex" alignItems="center" gap={1}>
                    <FormButtonCancel variant="outlined" onClick={onClose}>Annuler</FormButtonCancel>
                    <FormButton onClick={onSubmit}>
                        {loading ? <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} /> : null} Mettre à jour
                    </FormButton>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default CollaboratorEditModal;
