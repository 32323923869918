import { createTheme } from '@mui/material/styles';

// Define global typography styles
const theme = createTheme({
    typography: {
       fontFamily: 'Lato, sans-serif',
    h1: {
    
      fontWeight: 700,
      fontSize: '2.5rem',
    },
    h2: {
      fontWeight: 700,
      fontSize: '2rem',
    },
    h3: {
      fontWeight: 700,
      fontSize: '1.75rem',
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.5rem',
    },
    h5: {
      fontWeight: 700,
      fontSize: '1.25rem',
    },
    h6: {
      fontWeight: 700,
      fontSize: '1rem',
    },
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875rem',
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem',
    },
  },
});

export default theme;
