import React, { useEffect } from 'react';
import {Grid, Card, CardContent, Typography, Button, Box, LinearProgress, Chip } from '@mui/material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import Colors from 'src/styles/Colors';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PropTypes from 'prop-types';

const DashboardWidget = ({ role }) => {


// Fetching Data Based on Role
useEffect(() => {
    if (role === 'admin') {
      // Fetch admin data
    } else if (role === 'manager') {
      // Fetch manager data
    }
  }, [role]);
  
  return (
    <div style={{ display:'flex', flexDirection:'column',margin: 0, padding: '0 16px' }}>
      <Typography variant="h6" color={Colors.DARK_BLUE}>Tableau de bord</Typography>
  
            {role === 'admin' ? (
                console.log("Admin")
            ) : (
                  // Render manager-specific content
                console.log("Oussama manager")
                  
            )}

      <Grid container spacing={2}>
        {/* 1st Column */}
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              backgroundColor: Colors.DARK_GREEN,
              position: 'relative',
              overflow: 'hidden',
              height: '100%',
            }}
          >
            <CardContent>
              <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px', backgroundColor: Colors.GREEN, borderRadius: "8px" }}>
                  <GroupsOutlinedIcon fontSize="medium" style={{ color: "#fff" }} />
                </div>
              </div>
              <Typography variant="h4" component="div" color="#fff" sx={{ mt: 3, mb: 1 }}>
                123
              </Typography>
              <Typography variant="h6" component="div" style={{ lineHeight: '1.2' }} gutterBottom color="#fff">
                Nombre de Diagnostics en cours
              </Typography>
            </CardContent>
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: '50px',
                height: '100px',
                backgroundImage: "url('/network-vector.png')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                zIndex: 1,
              }}
            />
          </Card>
        </Grid>

        {/* 2nd Column */}
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              backgroundColor: Colors.DARK_BLUE,
              position: 'relative',
              overflow: 'hidden',
              height: '100%',
            }}
          >
            <CardContent>
              <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px', backgroundColor: Colors.VERY_DARK_BLUE, borderRadius: "8px" }}>
                  <PlaylistAddCheckOutlinedIcon fontSize="medium" style={{ color: "#fff" }} />
                </div>
              </div>
              <Typography variant="h4" component="div" color="#fff" sx={{ mt: 3, mb: 1 }}>
                123
              </Typography>
              <Typography variant="h6" component="div" style={{ lineHeight: '1.2' }} gutterBottom color="#fff">
                Nombre de Diagnostics Terminés
              </Typography>
            </CardContent>
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: '50px',
                height: '100px',
                backgroundImage: "url('/network-vector.png')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                zIndex: 1,
              }}
            />
          </Card>
        </Grid>

        {/* 3rd Column */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              gap: 2,
            }}
          >
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
                <Card sx={{ height: '100%', boxShadow: 2, position: 'relative' }}>
                  <CardContent>
                    <Typography variant="h6" component="div" gutterBottom>
                      Score Moyen
                    </Typography>
                    <Typography variant="h4" component="div" color="secondary" sx={{ mt: 2, lineHeight: 1, fontWeight: 800, color: '#334A52' }}>
                      789
                    </Typography>
                    <Typography sx={{ color: Colors.LIGHT_GREY, fontSize: '0.6rem' }} variant="caption" component="div">
                      <span style={{ color: Colors.GREEN }}>+11%</span> par rapport au Mois précédent
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: '5px',
                      width: '40px',
                      height: '40px',
                      backgroundImage: "url('/chart-up.png')",
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      zIndex: 1,
                    }}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
                <Card sx={{ height: '100%', boxShadow: 2, position: 'relative' }}>
                  <CardContent>
                    <Typography variant="h6" component="div" gutterBottom>
                      Alertes
                    </Typography>
                    <Typography variant="h4" component="div" color="secondary" sx={{ mt: 2, lineHeight: 1, fontWeight: 800, color: '#334A52' }}>
                      15
                    </Typography>
                    <Typography sx={{ color: Colors.LIGHT_GREY, fontSize: '0.6rem' }} variant="caption" component="div">
                      <span style={{ color: Colors.GREEN }}>+11%</span> par rapport au Mois précédent
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: '5px',
                      width: '40px',
                      height: '40px',
                      backgroundImage: "url('/chart-up.png')",
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      zIndex: 1,
                    }}
                  />
                </Card>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: Colors.GREEN }}
              fullWidth
              startIcon={<AddOutlinedIcon fontSize='medium' />}
              sx={{
                fontSize: 16,
                fontWeight: 600,
                textTransform: 'none'
              }}
            >
              Ajouter une équipe
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Typography variant="h6" sx={{ mt: 2 }} color={Colors.DARK_BLUE}>Equipes en cours de diagnostic</Typography>

      {/* New Card */}
      <Card sx={{ mt: 2, flexGrow:1}}>
        <CardContent>
          <Grid container spacing={1}>
            {/* Column 1: Team Info */}
            <Grid item xs={12} sm={3}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="body2"  fontWeight="bold">Team Alpha</Typography>
                  <Typography variant="body2" color={Colors.DARK_GREEN}>IT</Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Column 2: Progress Bar */}
            <Grid item xs={12} sm={3} >
              <LinearProgress variant="determinate" value={75} sx={{ mt: 1 }} /> {/* Adjust the value as needed */}
              <Typography variant="caption">80%</Typography>
            </Grid>

            {/* Column 3: Status Block */}
            <Grid item xs={12} sm={3} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <Chip label="Diagnostic en cours" color="success" style={{color:'#fff'}} fontWeight="bold"/> {/* Replace with status as needed */}
            </Grid>

            {/* Column 4: Actions */}
            <Grid item xs={12} sm={3} sx={{display:'flex', alignItems: 'center', justifyContent:'end'}}>
              <Button variant="outlined" color="primary">Rapport</Button>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            {/* Column 1: Team Info */}
            <Grid item xs={12} sm={3}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="body2"  fontWeight="bold">Team Alpha</Typography>
                  <Typography variant="body2" color={Colors.DARK_GREEN}>IT</Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Column 2: Progress Bar */}
            <Grid item xs={12} sm={3} >
              <LinearProgress variant="determinate" value={75} sx={{ mt: 1 }} /> {/* Adjust the value as needed */}
              <Typography variant="caption">80%</Typography>
            </Grid>

            {/* Column 3: Status Block */}
            <Grid item xs={12} sm={3} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <Chip label="Diagnostic en cours" color="success" style={{color:'#fff'}} fontWeight="bold"/> {/* Replace with status as needed */}
            </Grid>

            {/* Column 4: Actions */}
            <Grid item xs={12} sm={3} sx={{display:'flex', alignItems: 'center', justifyContent:'end'}}>
              <Button variant="outlined" color="primary">Rapport</Button>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            {/* Column 1: Team Info */}
            <Grid item xs={12} sm={3}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="body2"  fontWeight="bold">Team Alpha</Typography>
                  <Typography variant="body2" color={Colors.DARK_GREEN}>IT</Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Column 2: Progress Bar */}
            <Grid item xs={12} sm={3} >
              <LinearProgress variant="determinate" value={75} sx={{ mt: 1 }} /> {/* Adjust the value as needed */}
              <Typography variant="caption">80%</Typography>
            </Grid>

            {/* Column 3: Status Block */}
            <Grid item xs={12} sm={3} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <Chip label="Diagnostic en cours" color="success" style={{color:'#fff'}} fontWeight="bold"/> {/* Replace with status as needed */}
            </Grid>

            {/* Column 4: Actions */}
            <Grid item xs={12} sm={3} sx={{display:'flex', alignItems: 'center', justifyContent:'end'}}>
              <Button variant="outlined" color="primary">Rapport</Button>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            {/* Column 1: Team Info */}
            <Grid item xs={12} sm={3}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="body2"  fontWeight="bold">Team Alpha</Typography>
                  <Typography variant="body2" color={Colors.DARK_GREEN}>IT</Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Column 2: Progress Bar */}
            <Grid item xs={12} sm={3} >
              <LinearProgress variant="determinate" value={75} sx={{ mt: 1 }} /> {/* Adjust the value as needed */}
              <Typography variant="caption">80%</Typography>
            </Grid>

            {/* Column 3: Status Block */}
            <Grid item xs={12} sm={3} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <Chip label="Diagnostic en cours" color="success" style={{color:'#fff'}} fontWeight="bold"/> {/* Replace with status as needed */}
            </Grid>

            {/* Column 4: Actions */}
            <Grid item xs={12} sm={3} sx={{display:'flex', alignItems: 'center', justifyContent:'end'}}>
              <Button variant="outlined" color="primary">Rapport</Button>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            {/* Column 1: Team Info */}
            <Grid item xs={12} sm={3}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="body2"  fontWeight="bold">Team Alpha</Typography>
                  <Typography variant="body2" color={Colors.DARK_GREEN}>IT</Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Column 2: Progress Bar */}
            <Grid item xs={12} sm={3} >
              <LinearProgress variant="determinate" value={75} sx={{ mt: 1 }} /> {/* Adjust the value as needed */}
              <Typography variant="caption">80%</Typography>
            </Grid>

            {/* Column 3: Status Block */}
            <Grid item xs={12} sm={3} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <Chip label="Diagnostic en cours" color="success" style={{color:'#fff'}} fontWeight="bold"/> {/* Replace with status as needed */}
            </Grid>

            {/* Column 4: Actions */}
            <Grid item xs={12} sm={3} sx={{display:'flex', alignItems: 'center', justifyContent:'end'}}>
              <Button variant="outlined" color="primary">Rapport</Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};
DashboardWidget.propTypes = {
    role: PropTypes.string.isRequired,  // Specify that role is a required string
  };
export default DashboardWidget;
