import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './Login'; // Adjust the path if necessary
import ResetPassword from './ResetPass'; // Adjust the path if necessary
import styled from '@emotion/styled';
import Colors from 'src/styles/Colors';
import ForgetPassword from './ForgetPassword';
import { Box } from '@mui/material';
import VerifyEmail from './VerifyEmail';

const Container = styled.div`
  display: flex;
  height: 100vh;
  background-image: url('/login-cover.JPG');
  background-size: cover;
  background-position: center;
`;

const PictureColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  color: #fff;
  padding: 50px;

   @media (max-width: 768px) {
    display: none;
  }
`;

const Logo = styled.img`
  width: 200px; /* Adjust as needed */
  margin-bottom: 10px;
`;

const Title = styled.h3`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 8px;
  max-width: 400px;
`;



const Subtitle = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
  color: #dde0e7;
  max-width: 450px;
`;
const Subtitle2 = styled.p`
  font-size: 14px;
  margin-bottom: 25px;
  color: #dde0e7;
  max-width: 450px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Adjust as needed */
  gap: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  max-width:480px;
`;

const GridItem = styled.div`
  background: transparent;
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  max-width: 140px;
  box-sizing: border-box;
`;

const BoldText = styled.span`
  font-weight: bold;
  display: block;
  font-size: 12px;
`;

const SmallText = styled.span`
  font-size: 10px;
  color: #dde0e7;
`;

const ContactInfo = styled.div`
  text-align: left;
  font-size: 12px;
  font-weight: 500;
`;

const ContactLink = styled.a`
  color: ${Colors.GREEN};
  font-weight: bold;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
const MobileLogo = styled.img`
  width: 150px; /* Adjust as needed */
  margin-bottom: 20px; /* Space between the logo and the form */
  
  @media (min-width: 769px) {
    display: none;
  }
`;
const FormColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;

   @media (min-width: 769px) {
    .mobile-logo {
      display: none;
    
    }
  }
     @media (max-width: 768px) {
     padding-top:50px;
     gap:100px;
     justify-content: flex-start;
  }
`;








const IconImage = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
`;

const GridItemContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 100%;
`;
const FormContainer = styled.div`
  width: 100%;
  max-width: 400px;
`;
const AuthContainer = () => {
  return (
    <Container style={{position:"relative"}}>
         {/* Sage Partner Section */}
         <Box sx={{ display:{xs:"none", sm:"block"},position:"absolute", bottom:"10px", right: "10px",width: '180px' }}>
          <img src="/sage-green.jpg" alt="Logo" style={{ width:"100%"}} />
         </Box>
        <PictureColumn>
        <Logo src="/logo-white.png" alt="Logo" />
        <Box>
            <Title>Bienvenue dans votre <span style={{ color: Colors.GREEN }}> cockpit stratégique</span> !</Title>    
            <Subtitle>Révélez le Potentiel de Votre Cabinet</Subtitle>
            <Subtitle2>Experts-Comptables, Transformez Votre Cabinet en 10 Dimensions Clés</Subtitle2>
        </Box>
        
        <GridContainer>
          <GridItem>
            <GridItemContent>
              <IconImage src="/ic_money.png" alt="Money" />
              <SmallText>Visualiser vos forces et opportunités</SmallText>
            </GridItemContent>
          </GridItem>
          <GridItem>
            <GridItemContent>
              <IconImage src="/ic_form.png" alt="Form" />
              <SmallText>Obtenir des recommandations sur mesure</SmallText>
            </GridItemContent>
          </GridItem>
          <GridItem>
            <GridItemContent>
              <IconImage src="/ic_assign.png" alt="Assign" />
              <SmallText>Accéder à des outils concrets pour progresser</SmallText>
            </GridItemContent>
          </GridItem>
       
        </GridContainer>
        <Subtitle>Votre excellence commence maintenant... Mesurez. Comprenez. Excellez</Subtitle>

        <ContactInfo>
          <p>Nouveau ? {' '}
          <ContactLink href="#">Créer un compte </ContactLink></p>
          <p>
            Appelez-nous au{' '}
            <ContactLink href="tel:8001301448">800 1301 448</ContactLink>
          </p>
        </ContactInfo>
        </PictureColumn>
        <FormColumn>
                {/* Mobile-only logo */}
          <MobileLogo src="/logo-white.png" alt="Logo" className="mobile-logo" />
        <FormContainer>
          <Routes>
            
            <Route path="/" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgetPassword />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
          
          </Routes>
        </FormContainer>
        </FormColumn>
    </Container>
  );
};

export default AuthContainer;
