import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';
import { setUser, setToken } from './store/actions/authActions';
import Manager from './components/manager/Manager';
import Admin from './components/admin/Admin';
import Contributor from './components/contributor/Contributor';
import RegisterForm from './components/auth/RegisterForm';
import AuthContainer from './components/auth/AuthContainer';
import Colors from './styles/Colors';
import Equipes from './components/manager/Equipes/Equipes';
import Bilan from './components/manager/Bilan';
import Questionnaire from './components/manager/Questionnaires/Questionnaire';
import Profil from './components/manager/Profil';
import AdminDashboard from './components/admin/Dashboard';
import ManagerDashboard from './components/manager/Dashboard';
import EquipesAdmin from './components/admin/Equipes';
import BilanAdmin from './components/admin/Bilan';
import QuestionnaireAdmin from './components/admin/Questionnaire';
import ProfilAdmin from './components/admin/Profil';
import EquipeList from './components/manager/Equipes/EquipeList';
import CollaboratorList from './components/manager/Equipes/CollaboratorList';
import QuestionnaireList from './components/manager/Questionnaires/QuestionnaireList';
import QuestionnaireReponse from './components/manager/Questionnaires/QuestionnaireReponse';
import ResultatsOutlet from './components/manager/Resultats/ResultatsOutlet';
import ResultatsList from './components/manager/Resultats/ResultatsList';

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const storedUser = JSON.parse(localStorage.getItem('user'));

    if (token && storedUser) {
      dispatch(setToken(token));
      dispatch(setUser(storedUser));
    }

    setLoading(false);
  }, [dispatch]);

  if (loading) {
    return <div style={{background: Colors.DARK_BLUE}}>Loading...</div>;
  }

  // Determine the redirection path based on the user's role
  const getDefaultRoute = () => {
    if (!user) return '/auth';
    switch (user.role) {
      case 'admin':
        return '/admin/dashboard';
      case 'manager':
        return '/manager/dashboard';
      case 'contributor':
        return '/contributor/dashboard';
      default:
        return '/auth';
    }
  };

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/auth/*" element={<AuthContainer />} />
          <Route path="/register" element={<RegisterForm />} />
          
          {/* Default route redirects based on the user's role */}
          <Route path="/" element={<Navigate to={getDefaultRoute()} />} />

          {/* Admin Role-based routes */}
          <Route path="/admin/*" element={user?.role === 'admin' ? <Admin /> : <Navigate to="/auth" />}>
            <Route index element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="equipes" element={<EquipesAdmin />} />
            <Route path="bilan" element={<BilanAdmin />} />
            <Route path="questionnaire" element={<QuestionnaireAdmin />} />
            <Route path="profil" element={<ProfilAdmin />} />

       
            
          </Route>
          {/* Manager Role-based routes */}
          <Route path="/manager/*" element={user?.role === 'manager' ? <Manager /> : <Navigate to="/auth" />}>
            <Route index element={<Navigate to="dashboard" replace />} /> 
            <Route path="dashboard" element={<ManagerDashboard />} />
            <Route path="equipes/*" element={<Equipes />}>
              <Route index element={<Navigate to="list" replace />} />
              <Route path="list" element={<EquipeList />} />
              <Route path="collaborateurs" element={<CollaboratorList />} />

            </Route>
            <Route path="questionnaire/*" element={<Questionnaire />}>
              <Route index element={<Navigate to="list" replace />} />
              <Route path="list" element={<QuestionnaireList />} />
              <Route path="passer" element={<QuestionnaireReponse />} />

            </Route>
            <Route path="diagnostics/*" element={<ResultatsOutlet />}>
              <Route index element={<Navigate to="list" replace />} />
              <Route path="list" element={<ResultatsList />} />
              <Route path="resultat" element={<ResultatsList />} />

            </Route>
            <Route path="bilan" element={<Bilan />} />
            <Route path="questionnaire" element={<Questionnaire />} />
            <Route path="profil" element={<Profil />} />

       
            
          </Route>
          <Route path="/manager/*" element={user?.role === 'manager' ? <Manager /> : <Navigate to="/auth" />} />
          <Route path="/contributor/*" element={user?.role === 'contributor' ? <Contributor /> : <Navigate to="/auth" />} />

          {/* Redirect any unmatched route to the default route */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
