import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Box, Avatar, Menu, MenuItem, Badge } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setUser, setToken } from '../../store/actions/authActions';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import Colors from 'src/styles/Colors';
import MenuIcon from '@mui/icons-material/Menu';
import { ucFirst } from 'src/helpers/utils';
import { fetchProfileData } from 'src/store/actions/profileActions';
import { API_URL } from 'src/config';

// eslint-disable-next-line react/prop-types
const Navbar = ({ onSidebarToggle }) => {
  const user = useSelector((state) => state.auth.user);
  const { loading, profile, error } = useSelector((state) => state.profile);

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
  
    dispatch(setUser(null));
    dispatch(setToken(null));
    
    //window.location.href = '/auth';
  };
  useEffect(() => {
    dispatch(fetchProfileData());
  }, [dispatch]);

  return (
    <AppBar position="static" sx={{ background: 'transparent', boxShadow: 'none' }}>
      <Toolbar
        style={{
            display:"flex",
            justifyContent:"space-between",
          height: '50px', // Set custom height
          minHeight: '50px', // Override default minHeight
          maxHeight:'50px',
          padding: '0 16px', // Optional: adjust padding if needed
        }}
      >
       
        <Box sx={{display: 'flex', gap:"5px", justifyContent: 'start', alignItems: 'center'}}>
            <IconButton
            color="inherit"
            sx={{ display: { xs: 'block', md: 'block' }, mr: 1 }} // Reduced margin-right
            onClick={onSidebarToggle}
            >
            <MenuIcon sx={{ color: Colors.GREEN, fontSize: 20 }} /> {/* Smaller icon size */}
            </IconButton>
          <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
            <Typography variant="h6" color="black" sx={{ fontSize: '1rem' }}> {/* Reduced font size */}
              Espace {ucFirst(user?.role)} <span style={{ color: Colors.GREEN }}>Keep Growing</span>
            </Typography>
          </Link>
          
        </Box>
       
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit" component={Link} to="/notifications">
            <Badge
              badgeContent={2}
              color="error"
              sx={{
                '& .MuiBadge-dot': {
                  backgroundColor: 'red',
                },
                '& .MuiBadge-standard': {
                  backgroundColor: 'red',
                  color: 'white',
                  fontSize: '0.6rem',
                  width: '1rem',
                  height: '1rem',
                  minWidth: '1rem',
                  borderRadius: '50%',
                  top: '3px',
                  right: '3px',
                },
              }}
            >
              <NotificationsNoneOutlinedIcon sx={{ color: 'black', fontSize: 20 }} /> {/* Smaller icon size */}
            </Badge>
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              ml: 1, // Reduced margin-left
            }}
            onClick={handleMenuOpen}
          >
            <Avatar
              alt={`${profile?.first_name} ${profile?.last_name}`}
              src={profile?.profile_picture ? API_URL+"/users/profile-picture/"+profile.profile_picture : '/user-profile.png'}
              sx={{ width: 32, height: 32, mr: 1, boxShadow: 2 }} 
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="body2"
                color={Colors.DARK_BLUE}
                fontWeight={800}
                lineHeight={1}
                sx={{ mb: '1px', fontSize: '0.8rem' }} 
              >
                {profile?.first_name} {profile?.last_name?.toUpperCase()}
              </Typography>
              <Typography
                variant="caption"
                color={Colors.GREEN}
                fontWeight={400}
                lineHeight={1}
                sx={{ fontSize: '0.7rem' }} 
              >
                {user?.role || 'Role Not Available'}
              </Typography>
            </Box>
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              sx: {
                width: 190, // Reduced width
                maxWidth: '100%',
              },
            }}
          >

            <MenuItem component={Link} to={`/${user?.role}/profil`}>Mon compte</MenuItem>
            <MenuItem onClick={handleLogout}>Déconnexion</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
